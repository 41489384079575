<script lang="ts" setup>
import {getInitials} from '@/helpers/initials'
import {useAuthStore} from '@/store/useAuthStore'

const {t} = useI18n()
const route = useRoute()
const store = useAuthStore()
const localePath = useLocalePath()

const isMobileNavigationOpen = ref(false)
const toggleMobileNavigation = () => (isMobileNavigationOpen.value = !isMobileNavigationOpen.value)

const initials = computed(() => getInitials(`${store.user?.data.first_name} ${store.user?.data.last_name}`))

watch(route, () => (isMobileNavigationOpen.value = false))

const adminRoute = computed(() => localePath('/admin/bookings'))

const isLoggingOut = ref(false)

const logout = async () => {
  try {
    isLoggingOut.value = true
    await store.logout()
  } finally {
    isLoggingOut.value = false
  }
}
</script>

<template>
  <nav class="container" aria-label="Main">
    <ul>
      <li>
        <NuxtLink :to="localePath('/')" class="logo">Work<span class="green">Snap</span></NuxtLink>
      </li>
    </ul>
    <ul id="menu" class="main-nav" :class="{showMobileNav: isMobileNavigationOpen}">
      <li>
        <NuxtLink :to="localePath('/spaces')">{{ t('navigation.spaces') }}</NuxtLink>
      </li>
      <li>
        <NuxtLink :to="localePath('/about-us')">{{ t('navigation.about') }}</NuxtLink>
      </li>
      <li>
        <NuxtLink :to="localePath('/contact')">{{ t('navigation.contact') }}</NuxtLink>
      </li>
      <li>
      <NuxtLink :to="localePath('/news')">{{ t('navigation.news') }}</NuxtLink>
    </li>
      <li class="large-down">
        <NuxtLink :to="localePath('/faq')">{{ t('navigation.support') }}</NuxtLink>
      </li>
      <template v-if="store.isAuthenticated && store.user">
        <li class="large-down">
          <NuxtLink class="avatar" :to="adminRoute">
            <RoundedImage :src="store.user?.data?.image" :alt="initials" :show-alt="true" />
            <div class="sr-only">{{ t('navigation.admin') }}</div>
          </NuxtLink>
        </li>
        <li class="large-down">
          <button type="button" class="reset logout" @click="logout" :disabled="isLoggingOut ?? undefined">
            {{ $t('navigation.logout') }}
          </button>
        </li>
      </template>
    </ul>

    <ul class="secondary-nav">
      <li>
        <LanguagePicker />
      </li>
      <li class="large-up">
        <NuxtLink :to="localePath('/faq')">{{ t('navigation.support') }}</NuxtLink>
      </li>
      <template v-if="store.isAuthenticated && store.user">
        <li class="large-up">
          <NuxtLink class="avatar" :to="adminRoute">
            <RoundedImage :src="store.user?.data?.image" :alt="initials" :show-alt="true" />
            <div class="sr-only">{{ t('navigation.admin') }}</div>
          </NuxtLink>
        </li>
        <li class="large-up">
          <button type="button" class="reset logout" @click="logout" :disabled="isLoggingOut ?? undefined">
            {{ $t('navigation.logout') }}
          </button>
        </li>
      </template>

      <li class="large-down">
        <button
          type="button"
          class="reset hamburger"
          aria-controls="menu"
          :aria-expanded="isMobileNavigationOpen"
          @click="toggleMobileNavigation"
        >
          <i class="fas fa-bars"></i>
          <span class="sr-only">{{ t('navigation.menu') }}</span>
        </button>
      </li>
    </ul>
  </nav>
</template>

<style scoped lang="postcss">
@import 'open-props/media';
nav {
  --nav-gap: 3.2rem;
  --nav-height: 8.4rem;
  --navigation-background-color: #fff;
  position: sticky;
  top: 0;
  height: var(--nav-height);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: var(--nav-gap);
  border-bottom: 1px solid var(--border-color);
  background-color: var(--background-color-primary);
  z-index: 9999;
  @media (--lg-n-below) {
    --nav-height: 6rem;
  }
  & .avatar {
    font-size: 4.8rem;
  }
  & ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: var(--nav-gap);
    &:not(.main-nav) {
      flex-basis: 0%;
      flex-grow: 1;
      flex-shrink: 1;
    }
    &.main-nav {
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 1;
      justify-content: center;
      @media (--lg-n-below) {
        position: fixed;
        top: var(--nav-height);
        left: 0;
        display: flex;
        flex-direction: column;
        height: 100vh;
        height: calc(100vh - var(--nav-height));
        height: calc(100dvh - var(--nav-height));
        width: 100%;
        background-color: var(--navigation-background-color);
        /* will-change: transform o; */
        opacity: 0;
        transition: opacity 500ms;
        @media (--motionOK) {
          transform: translateX(100%);
          transition: transform 500ms, opacity 500ms;
        }
        &.showMobileNav {
          opacity: 1;
          @media (--motionOK) {
            transform: translateX(0%);
          }
        }
      }
    }
    &.secondary-nav {
      justify-content: end;
    }
    & a {
      color: var(--secondary-color);
      text-decoration: none;
      border-bottom: 1px solid transparent;
      transition: border 0.5s;
      white-space: nowrap;
      &.logo {
        font-size: 3.2rem;
        font-weight: 500;
        border: 0;
        color: var(--grey-color);
        & .green {
          color: var(--primary-color);
        }
      }
      &:focus,
      &:hover {
        outline-style: solid;
        outline-color: transparent;

        border-bottom-color: var(--secondary-color);
      }
    }
  }
  & .hamburger {
    font-size: 2.4rem;
  }
}
</style>
