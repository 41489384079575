<script lang="ts" setup>
import {onClickOutside} from '@vueuse/core'
import { LocaleObject } from 'vue-i18n-routing'
const target = ref(null)

const switchLocalePath = useSwitchLocalePath()
const { locales, locale, t } = useI18n()
onClickOutside(target, event => (isOpen.value = false))

const availableLocales = computed(() => {
  return (locales.value as LocaleObject[]).filter(i => i.code !== locale.value)
})
const img = useImage()

const localeFlags = {
  'nl': img('/nl.png', { width: 24 }),
  'de': img('/de.png', { width: 24 }),
  'en': img('/gb.png', { width: 24 }),
}

const isOpen = ref(false)
const toggleMenu = () => (isOpen.value = !isOpen.value)
const closeMenu = () => isOpen.value = false

</script>

<template>
  <div class="dropdown" ref="target">
    <div class="dropdown-wrapper">
      <button
        type="button"
        class="picker"
        id="language-picker"
        role="combobox"
        aria-haspopup="true"
        :aria-expanded="isOpen"
        :title="t(`locales.${locale}`)"
        @click="toggleMenu"
      >
        <img :src="localeFlags[locale]" :alt="t(`locales.${locale}`)" />
      </button>
      <ul role="listbox" aria-label="language-picker" v-if="isOpen" >
        <li v-for="option in availableLocales">
          <NuxtLink
            class="button"
            type="button"
            :to="switchLocalePath(option.code)"
            @click="closeMenu"
          >
            <img :src="localeFlags[option.code]" :alt="t(`locales.${locale}`)" />
          </NuxtLink>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.dropdown {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  & .dropdown-wrapper {
    position: relative;
    & button.picker {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 0.8rem;
      appearance: none;
      white-space: nowrap;
      cursor: pointer;
      height: 2.4rem;
      /* padding: var(--dropdown-button-padding, 2.4rem); */
      background-color: var(--background-color-primary);
      border-radius: 0;
      border: 0;
      border-bottom: 1px solid var(--dropdown-button-border-color, transparent);
      transition: 500ms;
      width: 100%;

      &:focus {
        border-color: var(--dropdown-button-focus-border-color, var(--secondary-color));
      }
      & i {
        font-size: 1rem;
      }
      & .fa-chevron-down {
        margin-left: auto;
      }
      & :slotted(span) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      & span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    & ul {
      position: absolute;
      display: var(--dropdown-list-display, flex);
      grid-template-columns: var(--dropdown-list-grid-columns, none);
      flex-direction: column;
      top: calc(100% + 1rem);
      left: 0;
      margin: 0;
      padding: 0;
      list-style-type: none;
      filter: var(--drop-shadow);
      background-color: var(--background-color-primary);
      border: 1px solid var(--border-color);
      border-radius: var(--border-radius-small);
      z-index: var(--dropdown-list-z-index, 9999);
      overflow: auto;
      max-height: 30rem;
      min-width: 100%;
      & li {
        min-width: 100%;
        & a {
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-start;
          white-space: nowrap;
          width: 100%;
          padding: 1rem 2rem;
          gap: 0.8rem;
          color: var(--text-color-secondary);
          cursor: pointer;
          transition: 500ms;
          border-radius: var(--border-radius-small);
          border-radius: 0;
          background-color: var(--background-color-primary);
          width: 100%;
          text-decoration: none;
          &:focus-visible,
          &:hover {
            color: var(--text-color-primary);
            background-color: var(--background-color-secondary);
          }
          &[aria-selected] {
            cursor: default;
            color: var(--primary-color);
          }
        }
      }
    }
  }
}
</style>
